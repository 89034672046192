
import './App.css';
import Menu from './tools/Menu.js';
import sponsor1 from './sponsor.png';
function App() {
  return (
  <body className="App">
    <Menu/>
    <div style={{height:'100%'}}>
    <div  className="gridContainer bord mainContent" >
        <div className={'NavItem bord'}>
        <span>
        Respect All
        </span>
        <br/>
        <span>
        Fear None
        </span>
        </div>
        <div style={{backgroundColor:'black', height:'65vh',width:'100%',color:'white',textAlign:'center',alignContent:'center'}}>
        <span style={{textAlign:'center'}}>
        
        </span>
        </div>
        <div className={'NavItem bord'}>
        TCON Cyborg Tech
        </div>
        <div className={'NavItem bord'}> </div>
        <div className={'NavItem bord'} style={{alignContent:'center',height:'15vh'}}>
        <span style={{marginBottom: '25px'}}>Sponsored By</span>
        <img src={sponsor1} alt="CRC Transport" style={{height: '100px'}}/>
        </div>
        <div className={'NavItem bord'}></div>


    </div>
    </div>
    </body>
  );
}

export default App;
