import crest from './PcolaRugby.png';
import '../App.css';
function Menu(){
return (<div className={'bord gridContainer'} >
<div className={'NavItem'}>
<img src={crest} style={{height: '150px', marginLeft: '10px'}} alt="logo"/>
</div>
<div className={'NavItem bord'}>
<h1 style={{padding:'auto'}}>
Pensacola Aviators
</h1>
</div>
<div className={'NavItem bord'}>
<span>
Currently,
</span>
<br/>
<span>
No Live Broadcast.
</span>
</div>
</div>);

}

export default Menu;